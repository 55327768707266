import React, { useState } from 'react';
import './CheckPlagiarismForm.css'; // 引入CSS样式文件
import { sha256 } from 'js-sha256';
import UrlElement from './UrlElement';

const CheckPlagiarismForm = () => {
  const [paperTitle, setPaperTitle] = useState('');
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState(false); // 用于文件上传错误状态
  const [version, setVersion] = useState('intl'); // 假设默认选择国际版
  const [fileName, setFileName] = useState(''); 
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false); // 用于邮箱错误状态
  const [apiKey, setApiKey] = useState('');
  const [apiKeyError, setApiKeyError] = useState(false); // 用于密钥错误状态
  const [submissionResult, setSubmissionResult] = useState(null); // 用于存储提交结果
  const [isSuccess, setIsSuccess] = useState(false); // 用于标记提交是否成功
  const [documentSha256, setDocumentSha256] = useState(''); // 初始化为空字符串
  // 文件是否已上传的状态
  const [isFileUploaded, setIsFileUploaded] = useState(false);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'paperTitle':
        setPaperTitle(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'apiKey':
        setApiKey(value);
        break;
      default:
        break;
    }
  };

  const handleVersionChange = (e) => {
    setVersion(e.target.value);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file)
      // 假设文件名即文章标题，去掉扩展名
      const paperTitleWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
      setPaperTitle(paperTitleWithoutExtension);
      setFileName(file.name); // 更新状态为所选文件的名称
      setFileError(false); // 有文件选择后，隐藏错误提示
      setIsFileUploaded(true); // 标记文件已上传
      generateSha256(file); // 选择文件后生成SHA-256值
    }
  };

  // 计算文件的SHA-256值
  const generateSha256 = async (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const arrayBuffer = event.target.result;
      const sha256Hash = sha256(arrayBuffer);
      setDocumentSha256(sha256Hash); // 假设使用setDocumentSha256更新状态
    };
    reader.readAsArrayBuffer(file);
  };

  // 验证函数，检查邮箱和密钥是否为空
  const validateForm = () => {
    const emailValid = email.trim() !== '';
    const apiKeyValid = apiKey.trim() !== '';
    setEmailError(!emailValid);
    setApiKeyError(!apiKeyValid);
    const fileValid = file !== null;
    setFileError(!fileValid);
    return emailValid && apiKeyValid && fileValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      // 邮箱和密钥有效，执行提交逻辑
      console.log('提交的邮箱:', email);
      console.log('提交的密钥:', apiKey);
      // 实际的提交代码会在这里
    } else {
      // 如果无效，不提交表单，并显示错误
      console.log('表单验证失败');
      return ;
    }
    // 在这里添加提交表单的逻辑
    const formData = new FormData();
    formData.append('region', version === 'uk' ? 'uk' : 'intl');
    formData.append('document', file);
    formData.append('apiKey', apiKey);
    formData.append('email', email);
    formData.append('documentSha', documentSha256); // 添加SHA-256值到表单数据
    try {
      const response = await fetch('https://turnitins.vip:8443/turnitin/api/public/submissions', {
        method: 'POST',
        body: formData,
      });

    //   if (!response.ok) {
    //     throw new Error(`HTTP error! status: ${response.status}`);
    //   }
  
    //   let result;
    //   if (response.headers.get('content-type') === 'application/json') {
    //     result = await response.json(); // 尝试解析JSON
    //   } else {
    //     result = await response.text(); // 直接获取文本
    //   }
  
    //   setSubmissionResult(result); // 更新提交结果状态
    //   setIsSuccess(true);
    //   setTimeout(() => {
    //     window.location.reload(); // 提交成功后刷新页面
    //   }, 1000);
    // } catch (error) {
    //   console.error('Error submitting form:', error);
    //   setSubmissionResult(error.message);
    //   setIsSuccess(false);
    // }
    let result;
    if (!response.ok) {
      result = await response.text();
      throw new Error(`${result}`);
    }

    
    if (response.headers.get('content-type') === 'application/json') {
      result = await response.json(); // 尝试解析JSON
    } else {
      result = await response.text(); // 直接获取文本
    }
    console.log(result)
    // 使用alert()显示结果
    if (typeof result === 'string' && result === 'SUCCESS') {
      alert('提交成功');
      setTimeout(() => {
            window.location.reload(); // 提交成功后刷新页面
          }, 500);
    } else {
      alert('提交失败');
    }
    setIsSuccess(true); // 更新状态以反映提交尝试
  } catch (error) {
    console.error('Error submitting form:', error);
    alert(`提交失败：${error.message}`);
    setIsSuccess(false);
  }
  };

  // 组件渲染完成后自动刷新页面（如果提交成功）
  React.useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        window.location.reload(); // 提交成功后刷新页面
      }, 3000); // 3秒后刷新页面
    }
  }, [isSuccess]);


  return (
<div className="check-plagiarism-form">
      <h1>Turnitins查重+AI检测服务</h1>
      <form onSubmit={handleSubmit} className="form-container">
      <div className="version-selection">
      <label className="version-label" htmlFor="version">选择版本：</label>
        <div className="radio-group">
          <label className="radio-label">
            <input
              type="radio"
              value="intl"
              checked={version === 'intl'}
              onChange={handleVersionChange}
            />
            国际版
          </label>
          <label className="radio-label">
            <input
              type="radio"
              value="uk"
              checked={version === 'uk'}
              onChange={handleVersionChange}
            />
            UK版
          </label>
        </div>
      </div>
      <hr className="divider" /> {/* 分界线 */}
        
        {/* 提示内容 */}
        <div className="instructions">
          <p>
            语种：英语、捷克语、德语、西班牙语、法语、意大利语、荷兰、波兰语、葡萄牙语、俄语、罗马语、越南语、土耳其语、日本语、韩语、阿拉伯语
          </p>
          <p>
            支持：外语毕业论文、留学生作业、SCI\EI\ACS等期刊、职称论文查重，系统包含Ai检测无需额外付费
          </p>
          <p>
            版本：UK版比国际版多一个英国学生论文库，非英国学校或机构请选择国际版。(uk版包含国际版)
          </p>
          <p>
            说明：引用和文献也在查重范围内，为保证报告精准度请务必全文复制提交检测。
          </p>
        </div>
        {isFileUploaded && (<div className="form-group">
          <label htmlFor="paperTitle">文章标题:</label>
          <input
            type="text"
            id="paperTitle"
            name="paperTitle"
            value={paperTitle}
          />
        </div>)}
        <div className="form-group">
          <label htmlFor="email">邮箱地址:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          {emailError && <p className="error-message">邮箱地址不能为空</p>}
        </div>
        <div className="form-group">
          <label htmlFor="apiKey">密钥:</label>
          <input
            type="text"
            id="apiKey"
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
            required
          />
          {apiKeyError && <p className="error-message">密钥不能为空</p>}
        </div>
        <div className="file-upload-container">
          <label htmlFor="fileUpload">文章上传：</label>
          <input
            id="fileUpload"
            type="file"
            accept=".pdf,.doc,.docx" 
            style={{ display: 'none' }} // 隐藏原生文件选择按钮
            onChange={handleFileChange}
          />
        {
          fileName ? (
            <div className="file-name">{fileName}</div> // 如果有文件名，显示它
          ) : (
            <button type="button" className="custom-file-button" onClick={() => document.getElementById('fileUpload').click()}>选择文件</button>
          )
        }
        </div>
        {fileError && <p className="error-message">请上传文件</p>} {/* 文件上传错误提示 */}
        <label className="version-label" htmlFor="version">您提交的论文不会收录到系统，请放心检测！</label>
        <label className="version-label" htmlFor="version">提交完成之后请耐心等待几分钟，邮箱将会收到检测报告, 如邮箱填错请联系淘宝客服</label>
        <label className="version-label" htmlFor="version"> <UrlElement url="https://item.taobao.com/item.htm?spm=a21dvs.23580594.0.0.621e2c1bTzMBqS&ft=t&id=810682161023" text="淘宝购买链接"/> </label>
        <label className="version-label" htmlFor="version"> <UrlElement url="https://www.xiaohongshu.com/goods-detail/667e7755d52fff0001f7fd37?instation_link=xhsdiscover://goods_detail/667e7755d52fff0001f7fd37%3Ftrade_ext=eyJjaGFubmVsSW5mbyI6bnVsbCwiZHNUb2tlbkluZm8iOm51bGwsInNoYXJlTGluayI6Imh0dHBzOi8vd3d3LnhpYW9ob25nc2h1LmNvbS9nb29kcy1kZXRhaWwvNjY3ZTc3NTVkNTJmZmYwMDAxZjdmZDM3P2FwcHVpZD01Y2ViODU4NDAwMDAwMDAwMTAwMWFkYTQiLCJsaXZlSW5mbyI6bnVsbCwic2hvcEluZm8iOm51bGwsImdvb2RzTm90ZUluZm8iOm51bGx9&share_id=133af92aa6a24db3aeb9d908a0b296f6" text="小红书购买链接"/></label>
        <button type="submit" className="submit-button">提交</button>
      </form>
      {submissionResult && (
        <div className="submission-result">
          {isSuccess ? '提交成功' : '提交失败'}
          <p>{submissionResult}</p>
        </div>
      )}
    </div>
  );
};

export default CheckPlagiarismForm;