import React from 'react';

const UrlElement = ({ url, text }) => {
  const linkStyle = {
    color: '#007BFF',
    textDecoration: 'none',
    transition: 'color 0.3s',
    fontSize: '16px', // 可以根据需要调整字体大小
  };

  const hoverStyle = {
    color: '#0056b3',
    textDecoration: 'underline',
  };

  return (
    <a href={url} style={linkStyle} onMouseOver={() => linkStyle.hoverStyle} onMouseOut={() => linkStyle}>
      {text}
    </a>
  );
};

export default UrlElement;